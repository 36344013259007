import { extendTheme } from "@chakra-ui/react";
import "@fontsource/archivo-narrow";

const colors = {
  brand: {
    900: "#364830",
    800: "#B8702B",
    700: "#E08A43",
    600: "#A6AF49",
    500: "#4B3319",
    400: "#BE742D",
  },
  rgba: {
    black: "rgba(0, 0, 0, 0.7)",
    green: "rgba(166, 175, 73, 0.3)",
    gray: "rgba(239, 239, 240, 1)",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
};

const breakpoints = {
  sm: "300px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};

const fonts = {
  heading: `'Archivo Narrow', 'sans-serif'`,
  body: `'Archivo Narrow', 'sans-serif'`,
};

export const Theme = extendTheme({ colors, fonts, breakpoints });
