import { Flex, Image, Text } from "@chakra-ui/react";

import {
  Bradesco,
  Agl,
  Cais,
  Cepe,
  Copergas,
  Cultura,
  Floarte,
  GovPE,
  LIC,
  Olho,
  Petrovia,
  Schweppes,
  Sherwin,
  Toyolex,
  Turismo,
  Uninassau,
  Apoio,
  Patrocinio,
  Realizacao,
  Incentivo,
} from "../../assets";

import { displayStyle, displayStyleTwo } from "./utils";

export const Footer = () => {
  return (
    <Flex
      bg="brand.900"
      w="100%"
      h={{
        base: "7rem",
        xl: "7rem",
        lg: "7rem",
        md: "7rem",
        sm: "auto",
      }}
      bottom="0"
      position={{
        base: "fixed",
        xl: "fixed",
        lg: "fixed",
        md: "fixed",
        sm: "initial",
      }}
      align="center"
      justify="center"
      direction="column"
    >
      <Flex w="100%" align="center" justify="center" bg="white" h="100%">
        <Text
          fontSize={{
            base: "xl",
            xl: "xl",
            lg: "xl",
            md: "md",
            sm: "sm",
          }}
          fontWeight="bold"
          color="brand.900"
          mb="0.5rem"
          textAlign={{ sm: "center" }}
        >
          23 de setembro a 30 de novembro de 2022. Cais do Sertão, Av Alfredo
          Lisboa, s/n, Recife-PE
        </Text>
      </Flex>
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        direction={{
          base: "row",
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
        }}
      >
        <Image
          src={Incentivo}
          maxW="2rem"
          minW="1rem"
          ml="2rem"
          display={displayStyle}
        />
        <Text
          color="brand.700"
          fontSize="xl"
          mt="2rem"
          fontWeight="bold"
          display={displayStyleTwo}
          textTransform="uppercase"
        >
          Incentivo
        </Text>
        <Image src={LIC} maxW="4.5rem" minW="1rem" />

        <Image src={Apoio} maxW="2rem" minW="1rem" display={displayStyle} />
        <Text
          color="brand.700"
          fontSize="xl"
          mt="2rem"
          fontWeight="bold"
          display={displayStyleTwo}
          textTransform="uppercase"
        >
          Apoio
        </Text>
        <Image src={Sherwin} maxW="4rem" minW="1rem" />
        <Image src={Schweppes} maxW="4rem" minW="1rem" />
        <Image src={Cepe} maxW="3rem" minW="1rem" />
        <Image src={Cais} maxW="4.5rem" minW="1rem" />
        <Image src={GovPE} maxW="3rem" minW="1rem" />

        <Image
          src={Patrocinio}
          maxW="2rem"
          minW="1rem"
          display={displayStyle}
        />
        <Text
          color="brand.700"
          fontSize="xl"
          mt="2rem"
          fontWeight="bold"
          display={displayStyleTwo}
          textTransform="uppercase"
        >
          Patrocínio
        </Text>
        <Image src={Bradesco} maxW="11rem" minW="1rem" mb="1rem" />
        <Image src={Uninassau} maxW="9.5rem" minW="1rem" />

        <Image
          src={Patrocinio}
          maxW="2rem"
          minW="1rem"
          display={displayStyle}
        />
        <Image src={Copergas} maxW="8rem" minW="1rem" mb="0.5rem" />
        <Image src={Petrovia} maxW="6rem" minW="1rem" mt="0.5rem" />
        <Image src={Floarte} maxW="4.5rem" minW="1rem" mt="0.5rem" />
        <Image src={Toyolex} maxW="5.5rem" minW="1rem" mt="0.5rem" />
        <Image src={Olho} maxW="5rem" minW="1rem" />

        <Image
          src={Realizacao}
          maxW="2rem"
          minW="1rem"
          display={displayStyle}
        />
        <Text
          color="brand.700"
          fontSize="xl"
          mt="2rem"
          fontWeight="bold"
          display={displayStyleTwo}
          textTransform="uppercase"
        >
          Realização
        </Text>
        <Image src={Agl} maxW="5rem" minW="1rem" />
        <Image src={Cultura} maxW="8rem" minW="1rem" />
        <Image
          src={Turismo}
          maxW="5.5rem"
          minW="1rem"
          mr={{
            base: "2rem",
            xl: "2rem",
            lg: "2rem",
            md: "0",
            sm: "0",
          }}
        />
      </Flex>
    </Flex>
  );
};
