import { Button, Flex } from "@chakra-ui/react";
import { Navbar } from "../Navbar";

import ButtonPrimer from "../../assets/images/ButtonPrimer.jpeg";
import ButtonFolder from "../../assets/images/ButtonFolder.jpeg";

import { useNavigate } from "react-router-dom";
import { Footer } from "../Footer";

export const HomeComponent = () => {
  const navigate = useNavigate();

  return (
    <Flex
      minH="100vh"
      w="100%"
      bg="white"
      direction="column"
      overflowX="hidden"
      align="center"
    >
      <Navbar />
      <Flex
        align="center"
        h="75vh"
        w="100%"
        direction="column"
        justify="center"
      >
        <Button
          bgImage={ButtonFolder}
          w="23rem"
          mb="3rem"
          onClick={() => navigate("/folder")}
          h="8rem"
          bgSize="80%"
          bg="none"
          bgPos="center"
          bgRepeat="no-repeat"
          _hover={{ opacity: 0.8 }}
          _active={{}}
          _focus={{}}
        />
        <Button
          bgImage={ButtonPrimer}
          w="23rem"
          mb="6rem"
          onClick={() => navigate("/cartilha")}
          h="8rem"
          bgSize="80%"
          bg="none"
          bgPos="center"
          bgRepeat="no-repeat"
          _hover={{ opacity: 0.8 }}
          _active={{}}
          _focus={{}}
        />
      </Flex>
      <Footer />
    </Flex>
  );
};
