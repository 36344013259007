export const displayStyle = {
  base: "block",
  xl: "block",
  lg: "block",
  md: "block",
  sm: "none",
};

export const displayStyleTwo = {
  base: "none",
  xl: "none",
  lg: "none",
  md: "none",
  sm: "block",
};
