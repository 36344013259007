import { Button, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { pdfEnglish, pdfPortuguese, pdfSpanish } from "../../assets";

import PdfPreview from "../PDF/PdfPreview";

export const FolderComponent = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState("portuguese");

  const changePdf = () => {
    if (language === "portuguese") {
      return pdfPortuguese;
    } else if (language === "english") {
      return pdfEnglish;
    } else {
      return pdfSpanish;
    }
  };

  return (
    <Flex
      minH="100vh"
      h="auto"
      direction="column"
      overflowX="hidden"
      align="center"
      bgColor="rgba.gray"
    >
      <Button
        mt="1rem"
        bg="brand.400"
        color="white"
        onClick={() => navigate("/")}
        _hover={{}}
        _active={{}}
        _focus={{}}
      >
        Voltar
      </Button>
      <PdfPreview
        pdf={changePdf()}
        setLanguage={setLanguage}
        language={language}
      />
    </Flex>
  );
};
