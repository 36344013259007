import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { memo, useState, ReactNode, useCallback, useLayoutEffect } from "react";
import { Page, Document, pdfjs } from "react-pdf";

import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdDownload,
} from "react-icons/md";
import { ButtonLanguages } from "../ButtonLanguages";

type PdfPreviewType = {
  pdf: string;
  hasTranslate?: boolean;
  language?: string;
  setLanguage?: any;
};

const PdfPreview = ({
  pdf,
  hasTranslate = true,
  language,
  setLanguage,
}: PdfPreviewType) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState<ReactNode>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [width, setWidth] = useState<number>(0);

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  const handleResize = () => setWidth(getWidth());

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };

  useLayoutEffect(() => {
    setWidth(getWidth());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changePdfSize = () => {
    if (language) {
      if (width < 600) {
        return 400;
      } else if (width > 600 && width < 1000) {
        return 700;
      } else {
        return 800;
      }
    } else {
      if (width < 600) {
        return 300;
      } else if (width > 600 && width < 1000) {
        return 550;
      } else {
        return 600;
      }
    }
  };

  const downloadPDF = () => {
    fetch(pdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `João Cabral de Melo Neto - ${language ?? "Cartilha"}`;
        alink.click();
      });
    });
  };

  return (
    <Flex direction="column" w="100%" justify="center" align="center" p="2rem">
      <Flex
        alignItems="center"
        bg="rgba.black"
        p="1rem"
        justifyContent="space-between"
        w="18rem"
        borderRadius="1rem"
      >
        <Button
          bg="brand.700"
          transition="all 0.5s"
          onClick={previousPage}
          _hover={{
            backgroundColor: "brand.800",
          }}
          _focus={{}}
          _active={{}}
          disabled={pageNumber === 1}
          _disabled={{ cursor: "not-allowed", opacity: 0.3 }}
        >
          <MdArrowBackIosNew color="white" />
        </Button>
        <Text color="white" fontWeight="bold">
          Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
        </Text>
        <Button
          bg="brand.700"
          transition="all 0.5s"
          onClick={nextPage}
          _hover={{
            backgroundColor: "brand.800",
          }}
          _focus={{}}
          _active={{}}
          disabled={pageNumber === numPages}
          _disabled={{ cursor: "not-allowed", opacity: 0.3 }}
        >
          <MdArrowForwardIos color="white" />
        </Button>
      </Flex>

      <Flex>
        <Button
          m={language ? "1.4rem 1rem 1rem 1rem" : "1.5rem"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bg="brand.700"
          color="white"
          transition="all 0.5s"
          _hover={{
            backgroundColor: "brand.800",
          }}
          _focus={{}}
          _active={{}}
          onClick={downloadPDF}
        >
          <Text>Download PDF</Text>
          <Icon as={MdDownload} />
        </Button>
      </Flex>
      {hasTranslate ? <ButtonLanguages setLanguage={setLanguage} /> : ""}

      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={changePdfSize()} />
      </Document>
    </Flex>
  );
};

export default memo(PdfPreview);
