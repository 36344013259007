import { Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { pdfPrimer } from "../../assets";
import PdfPreview from "../PDF/PdfPreview";

export const PrimerComponent = () => {
  const navigate = useNavigate();

  return (
    <Flex
      minH="100vh"
      h="auto"
      direction="column"
      overflowX="hidden"
      align="center"
      bgColor="rgba.gray"
    >
      <Button
        mt="1rem"
        bg="brand.400"
        color="white"
        onClick={() => navigate("/")}
        _hover={{}}
        _active={{}}
        _focus={{}}
      >
        Voltar
      </Button>
      <PdfPreview pdf={pdfPrimer} hasTranslate={false} />
    </Flex>
  );
};
