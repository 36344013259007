import { Button, Flex } from "@chakra-ui/react";

export const ButtonLanguages = ({ setLanguage }: any) => {
  return (
    <Flex mb="0.5rem">
      <Button
        m="0.5rem"
        bg="brand.700"
        color="white"
        onClick={() => setLanguage("portuguese")}
        _hover={{ background: "brand.800" }}
        _active={{}}
        _focus={{}}
      >
        Português
      </Button>
      <Button
        m="0.5rem"
        bg="brand.700"
        color="white"
        onClick={() => setLanguage("english")}
        _hover={{ background: "brand.800" }}
        _active={{}}
        _focus={{}}
      >
        English
      </Button>
      <Button
        m="0.5rem"
        bg="brand.700"
        color="white"
        onClick={() => setLanguage("spanish")}
        _hover={{ background: "brand.800" }}
        _active={{}}
        _focus={{}}
      >
        Español
      </Button>
    </Flex>
  );
};
