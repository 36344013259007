import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import JcmLogo from "../../assets/images/JcmLogo.png";

export const Navbar = () => {
  return (
    <Flex
      w="100%"
      h="25vh"
      bg="brand.900"
      justify="center"
      align="center"
      direction={{
        base: "row",
        xl: "row",
        lg: "row",
        md: "row",
        sm: "column",
      }}
    >
      <Image
        src={JcmLogo}
        h={{
          base: "80%",
          xl: "80%",
          lg: "80%",
          md: "70%",
          sm: "60%",
        }}
        mb={{ base: 0, xl: 0, lg: 0, md: 0, sm: "1rem" }}
      />
      <Flex
        direction="column"
        ml={{
          base: "20rem",
          xl: "20rem",
          lg: "20rem",
          md: "10rem",
          sm: "0",
        }}
        textAlign="end"
        mt={{
          base: "5.5rem",
          xl: "5.5rem",
          lg: "5.5rem",
          md: "3rem",
          sm: "0",
        }}
        align="end"
        lineHeight={{
          base: "3rem",
          xl: "3rem",
          lg: "3rem",
          md: "2rem",
          sm: "1rem",
        }}
      >
        <Heading
          color="brand.700"
          fontWeight="400"
          fontSize={{
            base: "3xl",
            xl: "3xl",
            lg: "3xl",
            md: "2xl",
            sm: "xl",
          }}
        >
          AFINAL, O QUE É POESIA?
        </Heading>
        <Text
          color="white"
          fontSize={{
            base: "lg",
            xl: "lg",
            lg: "lg",
            md: "md",
            sm: "sm",
          }}
          fontWeight="200"
        >
          Exposição Imersiva, Obra e Vida
        </Text>
      </Flex>
    </Flex>
  );
};
